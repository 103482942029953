import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { EditorProps, ToolbarOptions } from './editor.types';
import './editor.scss';

type EditorRef = Quill | null;

const TextEditor = forwardRef<EditorRef, EditorProps>(
  ({ readOnly = false, defaultValue, onTextChange, onSelectionChange, maxLength, toolbarOptions }: EditorProps, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const [currentLength, setCurrentLength] = useState(0);

    const getToolbarOptions = (toolbarOptions?: ToolbarOptions) => {
      const toolbar: any[] = [];

      const togleButtons: string[] = [];
      if (toolbarOptions?.enableBold) togleButtons.push('bold');
      if (toolbarOptions?.enableItalic) togleButtons.push('italic');
      if (toolbarOptions?.enableUnderline) togleButtons.push('underline');
      if (toolbarOptions?.enableLink) togleButtons.push('link');
      toolbar.push(togleButtons);

      if (toolbarOptions?.enableList) toolbar.push([{ list: 'ordered' }]);

      if (toolbarOptions?.enableClean) toolbar.push(['clean']);

      return toolbar.length > 0 ? toolbar : false;
    };

    // Update refs when props change
    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    // Handle enabling/disabling Quill editor
    useEffect(() => {
      (ref as React.MutableRefObject<EditorRef>).current?.enable(!readOnly);
    }, [ref, readOnly]);

    // Initialize Quill editor
    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
      editorContainer.style.minHeight = '80px';

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: getToolbarOptions(toolbarOptions), // Pass toolbarOptions here
        },
      });

      (ref as React.MutableRefObject<EditorRef>).current = quill;

      // Set default value
      if (defaultValueRef.current) {
        quill.setText(defaultValueRef.current);
        setCurrentLength(quill.getText().trim().length); // Initialize current length
      }

      // Attach event listeners
      quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
        const text = quill.getText().trim();
        const length = text.length;

        if (maxLength && length > maxLength) {
          quill.deleteText(maxLength, length);
        }

        maxLength && setCurrentLength(Math.min(length, maxLength));
        onTextChangeRef.current?.(delta, oldDelta, source);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        (ref as React.MutableRefObject<EditorRef>).current = null;
        container.innerHTML = '';
      };
    }, [ref, maxLength]);

    return (
      <div>
        <div ref={containerRef}></div>
        {maxLength && (
          <div className="max-length">
            {currentLength} / {maxLength}
          </div>
        )}
      </div>
    );
  },
);

TextEditor.displayName = 'TextEditor';

export default TextEditor;
