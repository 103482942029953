import { useLocation, Outlet, Link } from 'react-router-dom';
import React from 'react';
import { Button, Layout, Menu, MenuProps, Space } from 'antd';
import './eiq-manage-layout.scss';
import { SH } from 'common/components';
import { ReactComponent as CloudDownload } from 'assets/icons/cloud-download.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
import LogoIcon from 'assets/icons/eiq-logo.png';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';
import { userTypeMap } from 'common/constans/user-type.constants';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';

const { Header, Footer, Content } = Layout;

const items: MenuProps['items'] = [
  {
    label: 'lable',
    key: '/eiq-manage/residents',
  },
];

export const EiqManageLayout = observer((props: any) => {
  const location = useLocation();

  const currentMenuKey = (key: any) => {
    if (location.pathname.search(key) >= 0) {
      return location.pathname;
    } else {
      return key;
    }
  };

  let items: MenuProps['items'] = [
    {
      label: <SH.HotkeyLink to={'/eiq-manage/residents'} label={'Residents'} />,
      key: '/eiq-manage/residents',
    },
    {
      label: <SH.HotkeyLink to={'/eiq-manage/addresses'} label={'Addresses'} />,
      key: currentMenuKey('/eiq-manage/addresses'),
    },
    {
      label: <SH.HotkeyLink to="/eiq-manage/vehicles" label={'Vehicles/Devices'} />,
      key: '/eiq-manage/vehicles',
    },
  ].filter(Boolean);

  items.push({
    label: <SH.HotkeyLink to={'/eiq-manage/traffic'} label={'Traffic'} />,
    key: '/eiq-manage/traffic',
  });

  if (featureStore.isTrafficLogixEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/citations'} label="IntelliTicket" />,
      key: currentMenuKey('/eiq-manage/citation'),
    });
  }

  if (featureStore.isMassNotificationsEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/mass-notifications'} label="Mass Alerts" />,
      key: currentMenuKey('/eiq-manage/mass-notifications'),
    });
  }

  if (featureStore.isReportsEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/reports'} label="Insight" />,
      key: currentMenuKey('/eiq-manage/reports'),
    });
  }

  if (featureStore.isIncidentReportsEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/incident-reports'} label={'Incident Reporter'} className="incident-report" />,
      key: '/eiq-manage/incident-reports',
    });
  }

  items.push({
    label: <SH.HotkeyLink to={'/eiq-manage/system-configuration'} label={'Settings'} />,
    key: currentMenuKey('/eiq-manage/system-configuration'),
  });

  if (userAuthStore.userType === UserTypeEnum.SuperAdmin) {
    items = [
      {
        label: <SH.HotkeyLink to="/eiq-manage/account-management" label={'Account management'} />,
        key: currentMenuKey('/eiq-manage/account-management'),
      },
      {
        label: <SH.HotkeyLink to="/eiq-manage/feature-management" label={'Feature management'} />,
        key: '/eiq-manage/feature-management',
      },
    ].filter(Boolean);
  }

  const getSelectedKey = () => {
    const sortedItems = [...(items || [])].sort((a, b) => (String(b?.key).length || 0) - (String(a?.key).length || 0));
    for (const item of sortedItems) {
      if (item && item.key && location.pathname.startsWith(String(item.key))) {
        return item.key as string;
      }
    }

    return '/eiq-manage';
  };

  const selectedKey = getSelectedKey();

  return (
    <div className="eiq-manage">
      <Layout>
        <Header>
          <img className="logo" src={LogoIcon} alt="logo" />

          <Menu
            defaultSelectedKeys={[location.pathname]}
            className="cc-menu-item"
            items={items}
            mode="horizontal"
            selectedKeys={[selectedKey]}
          />

          {userAuthStore.userType === UserTypeEnum.Admin ? (
            <Link to={'/eiq-manage/account-management'}>
              <span className="user-name">{userAuthStore.userType && userTypeMap[userAuthStore.userType]}</span>
            </Link>
          ) : (
            <span className="user-name">{userAuthStore.userType && userTypeMap[userAuthStore.userType]}</span>
          )}

          <Button type="ghost" icon={<CloudDownload />} />
          <Button type="link" target="_blank" href="https://my.entranceiq.support/" icon={<Help />} />
          <Button
            type="ghost"
            icon={<Exit />}
            onClick={() => {
              userAuthStore.logOut();
            }}
          />
        </Header>
        <Content className="content-style">
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
});
