import React, { FC, ReactNode } from 'react';
import styles from './contact-block.module.scss';
import InfoField from '../info-field/info-field';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { IContactBlockProps } from './contact-block.types';
import dayjs from 'dayjs';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { getVacationPeriod } from '../../../helpers/vacation.helper';
import { NotificationStatuses } from './NotificationStatuses/NotificationStatuses';

const ContactBlock: FC<IContactBlockProps> = ({
  email,
  clubId,
  pin,
  vacationStartDate,
  vacationEndDate,
  ownershipType,
  relatedTo,
  notificaitonChannels,
}) => {
  const GetVacationField = (startDateString: string | null, endDateString: string | null): ReactNode => {
    const isVacation = startDateString && endDateString && dayjs().isBetween(startDateString, endDateString, 'day', '[]');

    if (!isVacation) {
      return <InfoField value={'Home'} label="Vacation" valueClassName="eiq-label primary" bodyClassName={styles.infoBlockField} />;
    } else {
      return <InfoField value={'Vacationing'} label="Vacation" valueClassName="eiq-label warning" bodyClassName={styles.infoBlockField} />;
    }
  };

  return (
    <>
      {relatedTo && (
        <div className={`${styles.infoBlockContainer} ${styles.relatedToContainer}`}>
          <InfoField label="Related to" value={`${relatedTo.firstName} ${relatedTo.lastName}`} bodyClassName={styles.relatedTo} />
        </div>
      )}
      <div className={styles.infoBlockContainer}>
        <div className={styles.infoLine}>
          <InfoField value={email} label="Email address" bodyClassName={styles.infoBlockField} valueIcon={<EmailIcon />} />
        </div>
        <div className={styles.infoLine}>
          <InfoField
            value={<NotificationStatuses channels={notificaitonChannels} />}
            label="Communication Consent"
            bodyClassName={styles.infoBlockField}
            valueClassName={styles.communicationConsent}
          />
        </div>
        <div className={`${styles.infoLine} ${styles.clubIdContainer}`}>
          <InfoField value={clubId} label="Club ID" bodyClassName={styles.infoBlockField} />
          {ownershipType !== ResidentTypesEnum.UnderAgeChild && <InfoField value={pin} label="Pin" bodyClassName={styles.infoBlockField} />}
        </div>
        {ownershipType !== ResidentTypesEnum.UnderAgeChild && ownershipType !== ResidentTypesEnum.AdultChild && (
          <div className={`${styles.infoLine} ${styles.vacationContainer}`}>
            {GetVacationField(vacationStartDate, vacationEndDate)}
            <InfoField
              value={getVacationPeriod(vacationStartDate, vacationEndDate) ?? 'Not available'}
              label="Vacation dates"
              bodyClassName={styles.infoBlockField}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ContactBlock;
