import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'common/stores/user-auth/user-auth.constants';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import loginService from 'features/login/service/login.service';

const refreshAccessToken = async (): Promise<string | null> => {
  const refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (!refreshToken) {
    return null;
  }

  try {
    const response = await loginService.refreshToken(refreshToken);
    userAuthStore.setAccessToken(response.refreshToken);

    return response.refreshToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

export const initializeSignalR = async (
  hubName: string,
  eventHandlers: { [eventName: string]: (...args: any[]) => void },
): Promise<HubConnection> => {
  const baseUrl = (process.env.REACT_APP_SERVER_BASE_URL ?? '').replace('/api', '');
  const connection = new HubConnectionBuilder()
    .withUrl(`${baseUrl}/${hubName}`, {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
      accessTokenFactory: async () => {
        let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || '';
        if (userAuthStore.isTokenExpired(accessToken)) {
          var token = await refreshAccessToken();

          if (!token) {
            userAuthStore.logOut();
          }
          return token || '';
        }
        return accessToken;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext) => {
        if (retryContext.previousRetryCount < 4) {
          return [0, 2000, 10000, 30000][retryContext.previousRetryCount];
        }
        return 45000;
      },
    })
    .build();

  try {
    await connection.start();

    for (const [eventName, handler] of Object.entries(eventHandlers)) {
      connection.on(eventName, handler);
    }
  } catch (error) {
    console.error('Error starting SignalR connection:', error);
  }

  return connection;
};
