import { makeAutoObservable } from 'mobx';

export interface ILincesePlateRecognitionData {
  licensePlate: string;
  licenseState: string;
  gate: string;
  camera: string;
  image?: string;
}

// Currently it stores only last recognition value. Probably we will need to implement QUEUE for multiple recognitions.
class LicensePlateRecognitionContext {
  recognitionData: ILincesePlateRecognitionData | null = null;

  constructor() {
    makeAutoObservable(this);
    this.recognitionData = null;
  }

  set(data: ILincesePlateRecognitionData) {
    this.recognitionData = data;
  }

  clear() {
    this.recognitionData = null;
  }

  get data() {
    return this.recognitionData;
  }
}

const plateRecognitionContext = new LicensePlateRecognitionContext();
export default plateRecognitionContext;
