import { NotificationType } from 'common/enums/notification-type.enum';
import { NotificationDisplayProps } from './NotificationStatuses.types';

export const NotificationStatuses: React.FC<NotificationDisplayProps> = ({ channels }) => {
  const filteredChannels = (channels ?? []).filter((channel) =>
    [NotificationType.SMS, NotificationType.Email].includes(channel.notificationType),
  );

  const getNotificationStatus = (allowsMassNotifications?: boolean | null): string => {
    if (allowsMassNotifications === true) return 'Allowed';
    if (allowsMassNotifications === false) return 'Not Allowed';
    return 'No Choice';
  };

  return (
    <>
      {filteredChannels.length > 0
        ? filteredChannels.map((channel) => (
            <span key={channel.notificationType}>
              {channel.notificationType.charAt(0).toUpperCase() + channel.notificationType.slice(1)}:{' '}
              {getNotificationStatus(channel.allowsMassNotifications)}
            </span>
          ))
        : null}
    </>
  );
};
