import { notification } from 'common/utils/notification';
import plateRecognitionContext, { ILincesePlateRecognitionData } from '../stores/license-plate-recognition-context';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';
import featureStore from 'common/stores/features/feature.store';

interface IGuestAdmittedNotificationMessage {
  gate: string;
  guestFullName: string;
  guestCompany?: string;
  residentFullName: string;
  address: string;
  camera: string;
  licensePlate: string;
}

interface IFailedAdmissionData {
  guestFullName?: string;
  residentFullName?: string;
  address?: string;
  gate: string;
  camera: string;
  licensePlate: string;
  licenseState: string;
  image?: string;
}

interface IFailedAdmissionNotificationMessage {
  reasonCode: string;
  data: IFailedAdmissionData;
}

const isValidState = () => {
  if (!userAuthStore.isLogin || userAuthStore.userType !== UserTypeEnum.SecurityOfficer) {
    return false;
  }

  if (!featureStore.isRealTimeNotificationsEnabled) {
    return false;
  }

  return true;
};

export const admissionNotificationHandlers = {
  GuestAdmitted: (message: IGuestAdmittedNotificationMessage) => {
    if (!isValidState()) {
      plateRecognitionContext.clear();
      return;
    }

    const { gate, guestFullName, guestCompany, residentFullName, address, camera, licensePlate } = message;
    let notificationMessage = `Guest ${guestFullName}`;

    if (guestCompany) {
      notificationMessage += ` (${guestCompany})`;
    }

    notificationMessage += ` is approved to visit resident ${residentFullName} at ${address}`;
    notificationMessage += ` via ${gate} ${camera} LPR Camera (License Plate: ${licensePlate})`;

    notification.success({ message: notificationMessage, duration: 0 });
  },
  GuestFailedAdmission: (message: IFailedAdmissionNotificationMessage) => {
    if (!isValidState()) {
      plateRecognitionContext.clear();
      return;
    }

    function notifyFailedAdmission(camera: string, text: string) {
      const notificationMessage = `LPR: ${text}. Camera: ${camera}`;
      notification.error({ message: notificationMessage, duration: 0 });
    }

    switch (message.reasonCode) {
      case 'UNKNOWN_ERROR':
        notifyFailedAdmission(message.data.camera, 'An error occurred during license plate recognition');
        break;
      case 'MULTIPLE_VEHICLES_FOUND':
        notifyFailedAdmission(
          message.data.camera,
          `Multiple vehicles found for license plate '${message.data.licensePlate}' and state '${message.data.licenseState}'`,
        );
        break;
      case 'UNSUPPORTED_REGION':
        notifyFailedAdmission(message.data.camera, `Unsupported region code: ${message.data.licenseState}`);
        break;
      case 'UNLISTED_GUEST':
        notifyFailedAdmission(message.data.camera, `Vehicle ${message.data.licensePlate} is not on the guest list`);
        break;
      case 'GUEST_EXPIRED':
        const text = `Guest ${message.data.guestFullName} is NOT approved to visit ${message.data.residentFullName} at ${message.data.address} because they are expired. Please review`;
        notifyFailedAdmission(message.data.camera, text);
        break;
    }

    let context: ILincesePlateRecognitionData = {
      camera: message.data.camera,
      licensePlate: message.data.licensePlate,
      licenseState: message.data.licenseState,
      image: message.data.image,
      gate: message.data.gate,
    };

    plateRecognitionContext.set(context);
  },
};
