import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { Tag } from 'antd';
import React from 'react';
import { ITrafficRead } from '../../../../common/services/traffic-service/traffic-service.types';
import { entryTypeColorMap, entryTypeMap } from '../../constans/common.eiq-secure';
import { admittanceColorMap, admittanceTypeMap } from 'common/constans/admittance.constants';
import { visitorTypeMap, visitorTypeMapColorMap } from 'common/constans/visitor.constants';
import { EntryType } from '../../../../common/enums/entry-type.enum';

import { AdmittanceType } from '../../../../common/enums/admittance-type.enum';
import BaseCellText from '../../../../common/components/table/base-cell-text';
import { formatCustomDateTime } from '../../../../common/helpers/date.helper';
import { TrafficLogVisitorType } from '../../../../common/constans/common.constants';
import { ReactComponent as Expired } from 'assets/icons/web-guest/expired.svg';
import { Image } from 'antd';
import dayjs from 'dayjs';

export const trafficColumns = () => ({
  entered: {
    key: 'timestamp',
    title: 'Entered',
    dataKey: 'timestamp',
    width: 150,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      return <BaseCellText>{formatCustomDateTime(cell?.rowData?.timestamp, true)}</BaseCellText>;
    },
  },
  direction: {
    key: 'direction',
    title: 'Direction',
    dataKey: 'direction',
    width: 150,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      const isEntry = cell?.cellData === 1;
      return (
        <Tag className="eiq-tag" color={isEntry ? '#C1FFEA' : '#DCDCDC'} style={{ color: '#292B2E' }}>
          {isEntry ? 'Entry' : 'Exit'}
        </Tag>
      );
    },
  },
  address: {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 136,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  residentName: {
    key: 'residentName',
    title: 'Resident’s name',
    dataKey: 'residentName',
    width: 200,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  guestName: {
    key: 'guestName',
    title: 'Guest’s name',
    dataKey: 'guestName',
    width: 170,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  guestType: {
    key: 'visitorType',
    title: 'Type',
    dataKey: 'visitorType',
    width: 200,
    minWidth: 160,
    resizable: true,
    sortable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      return (
        <Tag
          className="eiq-tag"
          color={visitorTypeMapColorMap[cell?.rowData?.visitorType as TrafficLogVisitorType]}
          style={{ color: '#292B2E' }}>
          {visitorTypeMap[cell?.rowData?.visitorType as TrafficLogVisitorType]}
        </Tag>
      );
    },
  },
  entryType: {
    key: 'entryType',
    title: 'Entry type',
    dataKey: 'entryType',
    width: 160,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      return (
        <Tag className="eiq-tag" color={entryTypeColorMap[cell?.rowData?.entryType as EntryType]}>
          {entryTypeMap[cell?.rowData?.entryType as EntryType]}
        </Tag>
      );
    },
  },
  gate: {
    key: 'gate',
    title: 'Gate',
    dataKey: 'gate',
    width: 130,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  camera: {
    key: 'camera',
    title: 'Camera',
    dataKey: 'camera',
    width: 130,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  carState: {
    key: 'carState',
    title: 'State',
    dataKey: 'carState',
    width: 105,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  carLicensePlate: {
    key: 'carLicensePlate',
    title: 'License Plate',
    dataKey: 'carLicensePlate',
    width: 220,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  deviceId: {
    key: 'deviceId',
    title: 'Device Number',
    dataKey: 'deviceId',
    width: 150,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  admittance: {
    key: 'admittanceType',
    title: 'Admittance',
    dataKey: 'admittanceType',
    width: 155,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      return (
        <Tag className="eiq-tag" color={admittanceColorMap[cell?.rowData?.admittanceType as AdmittanceType]} style={{ color: '#292B2E' }}>
          {admittanceTypeMap[cell?.rowData?.admittanceType as AdmittanceType]}
        </Tag>
      );
    },
  },
  turnBackReason: {
    key: 'turnBackReason',
    title: 'Reason',
    dataKey: 'turnBackReason',
    width: 180,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  image: {
    key: 'imageUrl',
    title: 'Image',
    dataKey: 'imageUrl',
    width: 152,
    fixed: 1,
    resizable: false,
    sortable: false,
    cellRenderer: (cell: any) => {
      return cell.cellData && <Image src={cell.cellData} width={24} height={24} />;
    },
  },
  companyName: {
    key: 'companyName',
    title: 'Company name',
    dataKey: 'companyName',
    width: 185,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  carExpirationDate: {
    key: 'carExpirationDate',
    title: 'Validity expiration date',
    dataKey: 'carExpirationDate',
    width: 250,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<ITrafficRead>) => {
      if (!cell.cellData) {
        return;
      }

      const expirationDate = dayjs(cell.cellData, 'YYYY-MM-DD').startOf('day');
      const dayToCompare = dayjs(cell.rowData.timestamp, 'YYYY-MM-DD').startOf('day');
      const isExpired = expirationDate.isBefore(dayToCompare);

      return isExpired ? (
        <Tag className="eiq-tag expired" icon={<Expired />}>
          {formatCustomDateTime(cell.cellData)}
        </Tag>
      ) : (
        `${formatCustomDateTime(cell.cellData)}`
      );
    },
  },
  driverName: {
    key: 'driverName',
    title: 'Driver’s name',
    dataKey: 'driverName',
    width: 185,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  driverCompanyName: {
    key: 'driverCompanyName',
    title: 'Driver’s company name',
    dataKey: 'driverCompanyName',
    width: 225,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  note: {
    key: 'note',
    title: 'Note',
    dataKey: 'note',
    width: 275,
    sortable: false,
    resizable: true,
    fixed: 1,
  },
});

const {
  gate,
  camera,
  carState,
  carLicensePlate,
  deviceId,
  direction,
  guestName,
  residentName,
  admittance,
  entered,
  image,
  turnBackReason,
  address,
  guestType,
  entryType,
  companyName,
  carExpirationDate,
  driverName,
  driverCompanyName,
  note,
} = trafficColumns();

export const trafficInitialColumns = [
  entered,
  direction,
  gate,
  camera,
  address,
  residentName,
  guestName,
  companyName,
  entryType,
  guestType,
  carState,
  carLicensePlate,
  deviceId,
  carExpirationDate,
  admittance,
  turnBackReason,
  driverName,
  driverCompanyName,
  note,
  image,
];
