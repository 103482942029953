import { GuestType } from 'common/enums/guest-type.enum';

export const guestTypeOptions = [
  {
    label: 'Guest',
    value: 'Guest',
  },
  {
    label: 'Service',
    value: 'Service',
  },
  {
    label: 'Delivery',
    value: 'Delivery',
  },
  {
    label: 'Ride share',
    value: 'RideShare',
  },
  {
    label: 'Family',
    value: 'Family',
  },
  {
    label: 'Urgent service',
    value: 'UrgentService',
  },
  {
    label: 'Health & Medical',
    value: 'Medical',
  },
  {
    label: 'Employee',
    value: 'Employee',
  },
];

export const serviceGuestTypeOptions = [
  {
    label: 'Service',
    value: 'Service',
  },
  {
    label: 'Delivery',
    value: 'Delivery',
  },
  {
    label: 'Ride share',
    value: 'RideShare',
  },
  {
    label: 'Urgent service',
    value: 'UrgentService',
  },
  {
    label: 'Health & Medical',
    value: 'Medical',
  },
];

export const guestTypeMap: Record<GuestType, string> = {
  [GuestType.Guest]: 'Guest',
  [GuestType.Service]: 'Service',
  [GuestType.UrgentService]: 'Urgent service',
  [GuestType.Employee]: 'Employee',
  [GuestType.Delivery]: 'Delivery',
  [GuestType.RideShare]: 'Ride share',
  [GuestType.Family]: 'Family',
  [GuestType.Medical]: 'Health & Medical',
  [GuestType.Undefined]: 'Undefined',
};
