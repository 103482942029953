import { makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode'; // Import the JWT decoding library
import React from 'react';
import loginService from 'features/login/service/login.service';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './user-auth.constants';
import { UserTypeEnum } from '../../enums/user-type.enum';
import configurationStore from '../configuration/configuration.store';
import featureStore from '../features/feature.store';
import userPersonalizationStore from '../user-personalization/user-personalization.store';
import zenDeskService from 'common/services/zendesk/zendesk.service';

export interface IUserProfile {
  id: number;
  name: string;
}

const NAME_CLAIM_PATH = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const ID_CLAIM_PATH = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';

class UserAuthStore {
  user: IUserProfile | null = null;
  isLogin = false;
  userType: UserTypeEnum | null = null;
  roles: string[] = [];
  permissions: string[] = [];
  profile: Record<string, any> | null = null;
  signUpValues: any = null;
  prevPath: string | null = null;
  webGuestResidentId: number | null = null;
  defaultGate: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restoreUserStore();
  }

  setUserData(accessToken: string, refreshToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    this.restoreUserStore();
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    this.restoreUserStore();
  }

  setSignUpValues(values: any) {
    this.signUpValues = values;
  }

  restoreUserStore() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!accessToken || accessToken === 'undefined') {
      return this.logOut();
    }

    this.resetUserStore();
    const decodedToken: any = jwt_decode(accessToken);

    const { user_type, permission, default_gate } = decodedToken;
    this.isLogin = true;
    this.userType = permission === 'self' ? UserTypeEnum.WebGuest : user_type;
    this.permissions = permission ?? [];
    this.defaultGate = default_gate;
    this.user = this.getUserFromToken(decodedToken);
    if (this.userType === UserTypeEnum.WebGuest) {
      return this.logOut();
    }
  }

  getUserFromToken(token: any) {
    return {
      id: token[ID_CLAIM_PATH],
      name: token[NAME_CLAIM_PATH],
    };
  }

  // setRoles(roles: string[]) {
  //   this.roles = roles;
  // }
  //
  // setPermissions(permissions: string[]) {
  //   this.permissions = permissions;
  // }
  //
  // setProfile(profile: Record<string, any>) {
  //   this.profile = profile;
  // }

  async requestToken(login: string, password: string) {
    try {
      console.log('requestToken');
      const { accessToken, refreshToken } = await loginService.authenticate(login, password);

      //const response = await loginService.authenticate(email, password);
      //console.log('requestToken step2', response);
      this.setUserData(accessToken, refreshToken);

      await userPersonalizationStore.requestUserPersonalization();
      await configurationStore.requestConfiguration();
      await featureStore.requestFeatures();
    } catch (error) {
      console.error('Error requesting tokens:', error);
    }
  }

  public resetUserStore() {
    this.isLogin = false;
    this.userType = null;
    this.roles = [];
    this.permissions = [];
    this.profile = null;
    this.user = null;
    this.signUpValues = null;
    this.webGuestResidentId = null;
    this.defaultGate = null;
  }

  logOut() {
    zenDeskService.logout();
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.prevPath = null;
    this.resetUserStore();
    this.isLogin = false;
    configurationStore.clearConfiguration();
    featureStore.clearFeatures();
    userPersonalizationStore.clearUserPersonalization();
  }

  setPrevPath = (path: string) => {
    this.prevPath = path;
  };

  isTokenExpired = (token: string) => {
    if (!token) {
      return true;
    }

    try {
      const decodedToken: any = jwt_decode(token);
      const exp = decodedToken.exp;
      const now = Math.floor(Date.now() / 1000);
      return exp < now;
    } catch (e) {
      return true;
    }
  };
}

const userAuthStore = new UserAuthStore();
export const UserAuthStoreContext = React.createContext<UserAuthStore>(userAuthStore);
export default userAuthStore;
