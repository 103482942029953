import { Card, Col, Divider, Row } from 'antd';
import { EmailNotifications } from './components/email-notifications';
import { PushNotifications } from './components/push-notifications';
import { SMSNotifications } from './components/sms-notifications';
import featureStore from 'common/stores/features/feature.store';

export const MassNotifications = () => {
  return (
    <Card className="eiq-card-border">
      <Row className="eiq-card-header">
        <Col>
          <h1 className="h1">EIQ-Alerts</h1>
        </Col>
      </Row>

      <Divider orientation="right">Email mass notifications</Divider>
      <EmailNotifications />

      <Divider orientation="right">Push mass notifications</Divider>
      <PushNotifications />

      {featureStore.isSMSMassNotificationsEnabled && (
        <>
          <Divider orientation="right">SMS mass notifications</Divider>
          <SMSNotifications />
        </>
      )}
    </Card>
  );
};
