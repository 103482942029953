import { EntryType } from '../../../common/enums/entry-type.enum';
import { DirectionEnum } from '../enums/direction.enum';
import { GuestType } from 'common/enums/guest-type.enum';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import { validityMap } from 'common/constans/common.constants';
import { DaysOfWeekSelection } from '../enums/days-of-week-selection.enum';
import { generateOption, generateOptionFromZero } from '../../../common/helpers/generate-option.helper';
import { IGateConfiguration } from 'common/services/configuration-service/configuration.service.types';

// Entry
export const entryTypeMap: Record<EntryType, string> = {
  [EntryType.Undefined]: 'Undefined',
  [EntryType.Driver]: 'Driver',
  [EntryType.Pedestrian]: 'Pedestrian',
  [EntryType.Passenger]: 'Passenger',
  [EntryType.Other]: 'Other',
};
export const entryOptions = generateOption(EntryType, entryTypeMap);
export const entryTypeColorMap: Record<EntryType, string> = {
  [EntryType.Undefined]: '#CCCCCC',
  [EntryType.Driver]: '#C1FFEA',
  [EntryType.Pedestrian]: '#D1E3FF',
  [EntryType.Passenger]: '#FFD889',
  [EntryType.Other]: '#E1E5EA',
};

// Direction
export const directionTypeMap: Record<DirectionEnum, string> = {
  [DirectionEnum.Exit]: 'Exit',
  [DirectionEnum.Entry]: 'Entry',
};
export const directionOptions = generateOptionFromZero(DirectionEnum, directionTypeMap);

export const directionColorMap: Record<DirectionEnum, string> = {
  [DirectionEnum.Entry]: '#C1FFEA',
  [DirectionEnum.Exit]: '#DCDCDC',
};

export const toGateOptions = (gates: IGateConfiguration[]) => gates.map((gate) => ({ label: gate.name, value: gate.name }));

const reasonList = ['No answer', 'No driver’s license', 'Not authorized'];

export const reasonsOptions = [
  {
    title: 'All reasons',
    value: 'SelectAll',
    children: reasonList.map((item) => ({ title: item, value: item })),
  },
];

export const reasonsRadioOptions = reasonList.map((reason) => ({ label: reason, value: reason }));

export const guestTypeMap: Record<GuestType, string> = {
  [GuestType.Undefined]: 'Undefined',
  [GuestType.Guest]: 'Guest',
  [GuestType.Service]: 'Service',
  [GuestType.Delivery]: 'Delivery',
  [GuestType.RideShare]: 'Ride share',
  [GuestType.Family]: 'Family',
  [GuestType.UrgentService]: 'Urgent service',
  [GuestType.Employee]: 'Employee',
  [GuestType.Medical]: 'Health & Medical',
};

export const guestTypeRadioOptions = [
  { value: GuestType.Guest, label: guestTypeMap[GuestType.Guest] },
  { value: GuestType.Service, label: guestTypeMap[GuestType.Service] },
  { value: GuestType.Delivery, label: guestTypeMap[GuestType.Delivery] },
  { value: GuestType.RideShare, label: guestTypeMap[GuestType.RideShare] },
  { value: GuestType.Family, label: guestTypeMap[GuestType.Family] },
  { value: GuestType.UrgentService, label: guestTypeMap[GuestType.UrgentService] },
  { value: GuestType.Medical, label: guestTypeMap[GuestType.Medical] },
  { value: GuestType.Employee, label: guestTypeMap[GuestType.Employee] },
];

export const validityRadioOptions = [
  { value: GuestValidity.OneTime, label: validityMap[GuestValidity.OneTime] },
  { value: GuestValidity.Today, label: validityMap[GuestValidity.Today] },
  { value: GuestValidity.Tomorrow, label: validityMap[GuestValidity.Tomorrow] },
  { value: GuestValidity.Permanent, label: validityMap[GuestValidity.Permanent] },
  { value: GuestValidity.DateRange, label: validityMap[GuestValidity.DateRange] },
];

export const daysOfWeekSelectionMap: Record<DaysOfWeekSelection, string> = {
  [DaysOfWeekSelection.AllDays]: 'All days',
  [DaysOfWeekSelection.Selected]: 'Selected',
};

export const daysOfWeekSelectionRadioOption = [
  { value: DaysOfWeekSelection.AllDays, label: daysOfWeekSelectionMap[DaysOfWeekSelection.AllDays] },
  { value: DaysOfWeekSelection.Selected, label: daysOfWeekSelectionMap[DaysOfWeekSelection.Selected] },
];

export const noAddressInstructionsMessage = 'There are no instructions for this address yet.';
