import { GuestType } from 'common/enums/guest-type.enum';
import { camelCaseToRegularForm } from './camel-case-to-regular-form.helper';

export const getGuestTypeValue = (value: string) => {
  const guestType = value as GuestType;

  switch (guestType) {
    case GuestType.Medical:
      return 'Health & Medical';
    default:
      return camelCaseToRegularForm(value);
  }
};
