import { Button, Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import notificationService from 'common/services/notification-service/notification-service';
import { useState } from 'react';

export const SMSNotifications = () => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSendNotification = async (values: { message: string }) => {
    try {
      await notificationService.sendSMSMassNotification(values.message.trim());
      notification.success({
        message: 'Success',
        description: 'SMS notification sent successfully!',
        duration: 1.5,
      });
    } catch (error) {
      console.error('Failed to send SMS notification:', error);
    }
  };

  const handleValuesChange = (changedValues: any) => {
    const message = (changedValues.message || '').trim();
    setIsButtonDisabled(message.length === 0);
  };

  return (
    <FormWrapper layout="vertical" form={form} onFinish={handleSendNotification} onValuesChange={handleValuesChange}>
      <Form.Item label="Send a Text (SMS) Message" name="message">
        <TextArea showCount maxLength={140} style={{ height: '80px' }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isButtonDisabled}>
          Send SMS notification
        </Button>
      </Form.Item>
    </FormWrapper>
  );
};
