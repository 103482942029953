export enum GuestType {
  Undefined = 'Undefined',
  Family = 'Family',
  Service = 'Service',
  RideShare = 'RideShare',
  Delivery = 'Delivery',
  Guest = 'Guest',
  UrgentService = 'UrgentService',
  Employee = 'Employee',
  Medical = 'Medical',
}
