import { Button, Form, notification } from 'antd';
import TextEditor from 'common/components/form-elements/editor/editor';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { useRef, useState } from 'react';
import Quill from 'quill';
import { EditorProps } from 'common/components/form-elements/editor/editor.types';
import notificationService from 'common/services/notification-service/notification-service';

export const EmailNotifications = () => {
  const [message, setMessage] = useState<string>('');
  const [textWithoutHtml, setTextWithoutHtml] = useState<string>('');

  const quillRef = useRef<Quill | null>(null);

  const handleTextChange = (content: string) => {
    setMessage(content);
  };

  const handleQuillChange = () => {
    if (quillRef.current) {
      const content = quillRef.current.root.innerHTML;

      setTextWithoutHtml(quillRef.current.getText().trim());
      handleTextChange(content);
    }
  };

  const EmailEditorProps: EditorProps = {
    readOnly: false,
    onTextChange: handleQuillChange,
    maxLength: 1000,
    toolbarOptions: {
      enableBold: true,
      enableItalic: true,
      enableUnderline: true,
      enableLink: true,
      enableList: true,
      enableClean: true,
    },
  };

  const handleSendNotification = async () => {
    try {
      await notificationService.sendEmailMassNotification(message);
      notification.success({
        message: 'Success',
        description: 'Email notification sent successfully!',
        duration: 1.5,
      });
    } catch (error) {
      console.error('Failed to send email notification:', error);
    }
  };

  return (
    <FormWrapper layout="vertical">
      <Form.Item label="Send an Email Message">
        <div>
          <TextEditor ref={quillRef} {...EmailEditorProps} />
        </div>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" onClick={handleSendNotification} disabled={textWithoutHtml.length === 0}>
          Send email
        </Button>
      </Form.Item>
    </FormWrapper>
  );
};
